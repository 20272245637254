<template>
    <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
            <v-card-title class="d-flex justify-center">
                <span class="text-h5 text-center mb-11">Приглашение сотрудника <br> в систему</span>
            </v-card-title>
            <v-card-text class="modal-create__form">
                <div class="close-dialog" @click="closeDialog">
                    <img :src="require('@/assets/img/common/close-dialog.svg')" />
                </div>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            class="evi-text-field"
                            color="#44D370"
                            v-model="email"
                            :rules="emailRules"
                            placeholder="Введите почту сотрудника"
                    >
                        <template v-slot:label>
                            Email
                            <span class="important">*</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col
                        cols="12"
                >
                    <v-text-field
                            class="evi-text-field"
                            color="#44D370"
                            v-model="name"
                            placeholder="Введите имя сотрудника"
                            :rules="nameRules"
                    >
                        <template v-slot:label>
                            Имя
                            <span class="important">*</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col
                        cols="12"
                >
                    <v-autocomplete
                            class="evi-autocomplete"
                            color="#44D370"
                            v-model="subdivision"
                            :items="subdivisions"
                            item-text="name"
                            item-value="id"
                            placeholder="Выберите подразделение"
                            :rules="subdivisionRules"
                            :loading="subdivisionLoading"
                            @focus="loadSubdivisions"
                            :search-input.sync="subdivisionSearch"
                            @input.native="searchSubdivision(subdivisionSearch)"
                            clearable
                            item-color="green"
                    >
                        <template v-slot:label>
                            Подразделение
                            <span class="important">*</span>
                        </template>
                        <template v-slot:append-item>
                            <div v-intersect="subdivisionIntersect" />
                        </template>
                        <template v-slot:append>
                            <span class="evi-select__arrow">
                                <v-icon>mdi-chevron-down</v-icon>
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-card-text>
            <v-card-actions class="modal-create__actions">
                <v-btn class="evi-button-green evi-button-green--fill" @click="invite">
                    Отправить приглашение
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import {SubdivisionAutocompleteRequestsMixin} from "@/mixins/autocomplete/SubdivisionAutocompleteRequestsMixin";

    export default {
        name: "InviteUser",
        mixins: [SubdivisionAutocompleteRequestsMixin],
        data: () => ({
            valid: true,
            email: '',
            name: '',
            subdivision: null,
            nameRules: [(v) => !!v || 'Введите имя'],
            subdivisionRules: [(v) => !!v || 'Выберите подразделение'],
        }),
        computed: {
            ...mapState("users", ['subdivisions']),
            emailRules() {
                return [
                    (v) => !!v || 'Введите почту',
                    (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Неверный формат эл. почты',
                ];
            },
        },
        methods: {
            ...mapActions({
                inviteUser: 'users/inviteUser',
                restoreActiveUser: 'payments/restoreActiveUser',
            }),
            validate () {
                return !!this.$refs.form.validate();
            },
            invite () {
                if (this.validate()) {
                    let payload = {
                        name: this.name,
                        email: this.email,
                        subdivision_id: this.subdivision,
                    };

                    this.inviteUser(payload).then(() => {
                        this.$emit('updateUsers');
                        this.restoreActiveUser();
                        this.closeDialog();
                    });
                }
            },
            closeDialog () {
                this.$emit('close');
                this.name = '';
                this.email = '';
                this.subdivision = null;
                this.$refs.form.resetValidation();
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
