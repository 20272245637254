<template>
  <div class="users">
    <div class="users__actions" v-if="hasRight('users.manage')">
      <v-btn class="evi-button-green evi-button-green--fill mr-3" @click="inviteUserModal = true">
        Пригласить сотрудника
      </v-btn>
      <div class="users__paid" v-if="paymentInfo">
        Сотрудников в системе <strong class="ml-2">{{ paymentInfo.active_users_count }} /
        {{ paymentInfo.paid_users_count }}</strong>
      </div>
    </div>
    <v-dialog
        v-model="inviteUserModal"
        max-width="612px"
    >
      <InviteUser @close="inviteUserModal = false" @updateUsers="updateUsers"/>
    </v-dialog>
    <EsFiltration :showByOptions="showByOptions" sortable :itemsLength="tableMeta.total" :showBy="24"
                  @updatePages="getUsersPages" :filter="filters">
      <template slot="sort-menu">
        <v-list dense class="settings-menu">
          <v-list-item-group>
            <v-list-item>
              <v-list-item-title>
                <v-autocomplete
                    class="evi-autocomplete mb-5"
                    color="#44D370"
                    v-model="filters['filter[roles.id]']"
                    :items="roles"
                    item-text="name"
                    item-value="id"
                    hide-details
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по ролям
                  </template>
                  <template v-slot:append>
                                  <span class="evi-select__arrow">
                                      <v-icon>mdi-chevron-down</v-icon>
                                  </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="v-select__selection v-select__selection--comma" :style="{color: `${item.color}`}">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <v-list-item-title :style="{color: `${item.color}`}">
                      {{ item.name }}
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    class="evi-autocomplete"
                    color="#44D370"
                    v-model="filters['filter[is_active]']"
                    :items="userStatuses"
                    item-text="name"
                    item-value="value"
                    hide-details
                    clearable
                    item-color="green"
                >
                  <template v-slot:label>
                    Фильтровать по статусу
                  </template>
                  <template v-slot:append>
                                  <span class="evi-select__arrow">
                                      <v-icon>mdi-chevron-down</v-icon>
                                  </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <div class="v-select__selection v-select__selection--comma" :style="{color: `${item.color}`}">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{item}">
                    <v-list-item-title :style="{color: `${item.color}`}">
                      {{ item.name }}
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </EsFiltration>
    <div v-if="loading" class="users__items">
      <v-skeleton-loader
          type="card"
          class="users__item"
          v-for="i in 8" :key="i"
      />
    </div>
    <div v-if="!loading" class="users__items">
      <div class="users__item" v-for="user in users" :key="user.id">
        <div class="users__info">
          <v-avatar
              color="#FFFFFF"
              size="100"
              @click="openUserPage(user.id)"
          >
            <img
                :src="user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <v-menu
              top
              offset-y
              open-on-hover
              max-height="156"
          >
            <template v-slot:activator="{ on, attrs }">
                        <span class="users__status" v-bind="attrs" v-on="on">
                            <img v-if="user.is_online" :src="require('@/assets/img/users/is_active.svg')"/>
                            <img v-else :src="require('@/assets/img/users/is_fired.svg')"/>
                        </span>
            </template>
            <v-list-item class="user-color-status d-flex justify-space-between align-center" v-if="user.is_online">
              <img class="mr-4" :src="require('@/assets/img/users/green.svg')"/>
              <span class="user-color-status--green">В сети</span>
            </v-list-item>
            <v-list-item class="user-color-status d-flex justify-space-between align-center" v-else>
              <img class="mr-4" :src="require('@/assets/img/users/red.svg')"/>
              <span class="user-color-status--red">Не в сети</span>
            </v-list-item>
          </v-menu>
          <v-menu
              bottom
              close-on-click
          >
            <template v-slot:activator="{ on, attrs }">
                        <span class="users-menu" v-bind="attrs" v-on="on">
                            <v-icon color="#2C3136">mdi-dots-horizontal</v-icon>
                        </span>
            </template>
            <v-list>
              <v-list-item class="user-menu__item" v-if="profile.id !== user.id">
                <v-list-item-title @click="chat(user.id)">Перейти в чат</v-list-item-title>
              </v-list-item>
              <v-list-item class="user-menu__item" v-if="canViewAnalytics(user)">
                <v-list-item-title @click="openUserAnalytics(user.id)">Посмотреть аналитику</v-list-item-title>
              </v-list-item>
              <v-list-item class="user-menu__item" v-if="!user.is_main && hasRight('users.manage')">
                <v-list-item-title class="red--text" v-if="user.is_active" @click="openRemoveUserModal(user)">Уволить
                </v-list-item-title>
                <v-list-item-title class="green--text" v-else @click="restoreUserBack(user)">Восстановить
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <h4 class="mt-4" @click="openUserPage(user.id)">{{ user.name }}</h4>
          <span class="users__email" @click="openUserPage(user.id)">{{ user.email }}</span>
        </div>
        <div class="users__role" v-if="user.roles[0]" :style="{color: `${user.roles[0].color}`}">
          {{ user.roles[0].name }}
        </div>
      </div>
      <v-dialog
          v-model="removeUserModal"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Увольнение: {{ selectedUser.name }}
          </v-card-title>
          <v-card-text>Вы действительно хотите уволить сотрудника?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="removeUserModal = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                @click="removeUser"
            >
              Уволить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import EsFiltration from "../../components/сommon/EsTable/EsFiltration";
import {mapActions, mapState} from "vuex";
import InviteUser from "../../components/users/InviteUser";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {hasRight} from '@/utils/access/hasRight';
import {RouteFilterPaginationMixin} from "../../mixins/RouteFilterPaginationMixin";

export default {
  name: "Users",
  components: {InviteUser, EsFiltration},
  mixins: [OpenPagesMixin, RouteFilterPaginationMixin],
  data: () => ({
    showByOptions: [
      {
        text: '4',
        counter: 4,
      },
      {
        text: '8',
        counter: 8,
      },
      {
        text: '12',
        counter: 12,
      },
      {
        text: '24',
        counter: 24,
      },
      {
        text: '50',
        counter: 50,
      },
    ],
    userStatuses: [
      {
        name: 'Работает',
        value: true,
        color: '#4CAF50',
      },
      {
        name: 'Уволен',
        value: false,
        color: '#F44336',
      },
    ],
    inviteUserModal: false,
    removeUserModal: false,
    selectedUser: {},
    filters: {
      'filter[roles.id]': undefined,
      'filter[is_active]': undefined,
    }
  }),
  computed: {
    ...mapState("users", ["users",]),
    ...mapState("user", ["profile"]),
    ...mapState("common", ["loading", "tableMeta"]),
    ...mapState("permissions", ["roles"]),
    ...mapState("payments", ["paymentInfo"]),
  },
  methods: {
    ...mapActions({
      getUsersPages: 'users/getUsersPages',
      fireUser: 'users/fireUser',
      restoreUser: 'users/restoreUser',
      getUserChat: 'chat/getUserChat',
      getRoles: 'permissions/getRoles',
      removeActiveUser: 'payments/removeActiveUser',
      restoreActiveUser: 'payments/restoreActiveUser',
    }),
    hasRight: hasRight,
    chat(id) {
      this.getUserChat(id).then((response) => {
        this.$router.push({path: `/chat/users/${response.id}`});
      });
    },
    canViewAnalytics(item) {
      if (this.profile.id !== item.id) {
        return this.hasRight('analytics.users');
      }
      return this.hasRight('analytics.usersOwn')
    },
    updateUsers() {
      this.getUsersPages({
        per_page: 8,
        page: 1,
        ['filter[is_active]']: true
      });
    },
    openRemoveUserModal(item) {
      this.selectedUser = item;
      this.removeUserModal = true;
    },
    removeUser() {
      this.fireUser(this.selectedUser).then(() => {
        this.removeActiveUser();
        this.updateUsers();
        this.removeUserModal = false;
      });
    },
    restoreUserBack(item) {
      this.selectedUser = item;
      this.restoreUser(this.selectedUser).then(() => {
        this.restoreActiveUser();
        this.updateUsers();
        this.removeUserModal = false;
      });
    },
  },
  mounted() {
    this.getRoles();
    this.routeFiltersInit(this.filters);
  },
  watch: {
    filters: {
      handler (val) {
        this.filterWatcher(val);
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.users {
  background: #FFFFFF;
  //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 60px 25px;
  margin-bottom: 25px;

  &__actions {
    margin-left: 23px;
    display: flex;
    align-items: center;
  }

  &__paid {
    margin-left: 30px;
    font-weight: 400;
    font-size: 14px;
    color: $evi-headline-color;
  }

  &__items {
    margin-left: 23px;
    margin-top: 43px;
    display: flex;
    flex-wrap: wrap;
    /*max-width: 1142px;*/
  }

  &__item {
    background: linear-gradient(149.26deg, #F2F2F2 27.83%, #EBF5F9 81.36%);
    border-radius: 12px;
    max-width: 241px;
    min-width: 241px;
    min-height: 274px;
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    margin-bottom: 45px;
  }

  &__info {
    height: 218px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      text-decoration-line: underline;
    }
  }

  &__email {
    font-weight: normal;
    font-size: 14px;
    color: #808386;
  }

  &__role {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 54px;
    border-top: 3px solid #FFFFFF;
  }

  &__status {
    position: absolute;
    top: 33px;
    right: 76px;
  }

  &-menu {
    position: absolute;
    top: 27px;
    right: 20px;
  }
}

.user-color-status {
  font-weight: 600;
  font-size: 15px;
  padding: 0 23px;

  &--green {
    color: #08A552;
  }

  &--red {
    color: #ED4245;
  }
}
</style>
